import { lazy } from "react";

const CallManagementApp = lazy(() => import("./CallManagementApp"));
const CallManagementConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/callManagement",
      element: <CallManagementApp />,
    },
  ],
};

export default CallManagementConfig;
