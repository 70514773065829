import { lazy } from "react";

const RTPCallListApp = lazy(() => import("./RTPCallListApp"));
const RTPCallListConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/rtpcallManagement",
      element: <RTPCallListApp />,
    },
  ],
};

export default RTPCallListConfigs;
