/** @format */
import { Korean } from "flatpickr/dist/l10n/ko.js";
const locale = {
  DASHBOARD: "대시보드",
  MYPAGE: "마이 페이지",
  GGR: "제공자 GGR",
  GAMEHISTORY: "게임플레이 내역",
  GAMELIST: "게임 목록",
  sync: "게임 동기화",
  GAMELISTSYNC: "게임 목록 동기화",
  PROVIDERMANAGEMENT: "베팅 한도 설정",
  CALLMANAGEMENT: "통화 관리",
  SERVICEDESK: "서비스 데스크",
  POT: "팟 내역",
  POT: "팟",
  AGGREGATOR_PROVIDER: "게임 프로바이더",
  Invest_Agent_GGR_Balance: "인베스트 에이전트 GGR 잔액",
  TOTAL_GGR_BALANCE: "총 GGR 잔액",
  Honor_Link: "아너링크",
  g_solution: "G-Solution",

  Vinus: "비누스",
  Vinus_skin: "Vinus(SKIN 5)",
  Vinus_skin2: "Vinus(SKIN 2)",
  Common_Invest: "공통 인베스트",
  AGENT: "에이전트",
  AllAGENTS: "모든 에이전트",
  NOTSETAGENTS: "미설정 에이전트",
  TRANSFERAGENTS: "트랜스퍼",
  SEAMLESS: "심리스",
  USERMONEYMANAGEMENT: "사용자 잔액 설정",
  CANCELBETS: "베팅 취소",
  TRANSACTIONHISTORY: "트랜잭션 기록",
  TRANSACTIONHISTORYAGENT: "에이전트 팟",
  TRANSACTIONHISTORYUSER: "유저 팟",
  allPotHistory: "팟 거래",
  TRANSACTIONHISTORYALL: "팟 거래",
  TRANSACTIONHISTORYADMIN: "어드민 팟",
  AllAgentLIST: "All Agent List",
  AGENTLIST: "에이전트 목록",
  AGENTTREELIST: "에이전트 트리 목록",
  CREATEAGENT: "에이전트 생성하기",
  REQUESTRPOINT: "pot 요청",
  RPOINTREQUESTEDLIST: "팟 요청 리스트",
  USER: "유저",
  FROM_USER_AGENT: "부터 (유저/에이전트)",
  BETHISTORY: "베팅 내역",
  USERLIST: "유저 목록",
  DEPOSITWITHDRAWAL: "입출금",
  CHARGINGHISTORY: "충전 내역",
  PAYMENTHISTORY: "지급 내역",
  STATISTISC: "통계",
  AGENTRSTATISTICS: "에이전트 매출 통계",
  SUBAGENTRSTATISTICS: "하위 에이전트 매출 통계",
  POTDESTRIBUTION: "포인트 분포 통계량",
  REVENUECASINO: "유저별 수익",
  REVENUECASINOALLMONTH: "월별 유저 수익",
  USERREVENUESTAT: "유저 수익 통계",
  DEVELOPER: "개발자용",
  VENDORLIST: "공급업체 목록",
  VENDORMANAGEMENT: "공급업체 목록",
  EditVendor: "공급업체 편집",
  AddVendor: "벤더 추가",
  VENDORNAME: "게임 벤더 이름",
  VendorNameKr: "벤더 이름 Kr",
  available: "가용성",
  language: "언어",
  Korea: "한국",
  Other: "다른",
  Korean: "한국어",
  KRW: "KRW",
  USD: "USD",
  JPY: "JPY",
  EUR: "EUR",
  BET: "내기",
  ASTRO: "아스트로 히스토리",
  ObjectData: "물체 데이터",
  VENDORID: "벤더 ID",
  GAMELIST: "게임 목록",
  VENDORENABLE: "공급업체 활성화",
  LOBBYLIST: "로비 목록",
  REVENUESTATICS: "수익 통계",
  UPLOADSUCCESS: "성공적으로 업로드되었습니다",
  BRANDINGMANAGEMENT: "브랜딩 관리",
  BRANDINGDETAILS: "브랜딩 세부 사항 업로드",
  UPLOADEDBRANDINGDETAILS: "업로드된 브랜딩 세부사항",
  LOGOIMG: "로고 이미지 업로드",
  FAVIIMG: "파비콘 이미지 업로드",
  SIGNUPBACKGROUNDIMG: "가입 배경 이미지 업로드",
  LOGOIMGUPLOADED: "로고 이미지",
  FAVIIMGUPLOADED: "파비콘 이미지",
  SIGNUPBACKGROUNDIMGUPLOADED: "가입하기 배경 이미지",
  Footer: "푸터",
  Branding_Unique_Name: "브랜딩 고유 이름",
  NATIVEAPI: "트랜스퍼 API",
  EXTENDEDAPI: "심리스 API",
  APIERRORLOG: "API 오류 로그",
  MTRPOINT: "팟 신청 목록",
  AGENTRECHARGESTAT: "에이전트 재충전 금액",
  USERBETTINGLIST: "유저 배팅 목록",
  GLOBALSETTING: "글로벌 설정",
  SETTINGBYTYPE: "유형별 설정",
  VENDORSPECIFICSETTING: "유형별 설정",
  GAMESEPECIFSETTING: "게임별 설정",
  USERBETTINGMANAGEMENT: "사용자별 베팅 제한 설정",
  Click_me: "클릭 해주세요",
  Excel: "엑셀",
  ACTION: "행동",

  Agent_listofAdmin: "트랜잭션 상세 내역",
  approved: "승인",
  reject: "거부됨",
  not_approved: "미승인",
  search: "검색",
  date_search: "날짜검색",
  Sales: "매출",
  Distribution: "유통 (팟만 유통하는 팟 유통사)",
  Operational: "운영 (사이트 운영사)",
  Concurrent: "병행 (팟 유통 및 사이트 운영 둘다)",
  agent_name: "에이전트 ID",
  Req_ID: "요청 아이디",
  no: "호",
  user_id: "유저 아이디 ",
  casino_user_id: "유저 ID",
  parent_id: "상위 아이디",
  clear: "초기화",
  noOfpoint: "팟",
  rows_per_page: "",
  status: "상태",
  status_code: "상태 코드",
  payment_amount: "지급액",
  balance_after_payment: "이후 잔액",
  receving_user_name: "받은 유저",
  point_amount: "포인트 금액",
  number: "번호",
  agent_distribution_statistics: "에이전트 배당 통계내역",
  step: "Step",
  affiliate_agent: "상위 에이전트 (닉네임)",
  affiliate_agent_balance: "상위 에이전트 잔액",
  affiliate_agent_ggr: "인베스트 에이전트 ID",
  affiliate_agent_balance_ggr: "인베스트 에이전트 잔액",
  agent: "에이전트",
  holding_amount: "보유금액",
  tot_amount_paid: "총지급액",
  tot_amount_charge: "총충전액",
  signup_time: "가입 시간",
  view_previous_month: "전월 현황",
  view_response: "요청 리스폰스",
  view_request: "요청 보기",
  view_next_month: "이번 달 현황",
  balance_amount: "잔액",
  select_provider: "공급자",
  provider: "공급자",
  logType: "로그 유형",
  operation_type: "작동 유형",
  requestPayload: "요청 페이로드",
  responsePayload: "응답 페이로드",
  user_deposit: "유저 입금",
  user_withdrawal: "유저 출금",
  export: "엑셀로 내보내기",
  affiliated_agent: "제휴 에이전트",
  id: "아이디",
  nick_name: "닉네임",
  agentNickname: "에이전트 아이디 (닉네임)",
  TRANSACTION_OPERATOR: "거래 에이전트",
  Round_id: "라운드 ID",
  Round_ID_copied_successfully: "라운드 ID가 성공적으로 복사되었습니다",
  Transaction_ID_copied_successfully: "트랜잭션 ID가 성공적으로 복사되었습니다",
  password: "비밀번호",
  rate: "요율",
  type: "유형",
  sub_type: "하위 유형",
  selection_rule_msg: "먼저 유형을 선택해주세요",
  allow_ip: "허용_IP",
  memo: "메모",
  rpoint: "팟",
  BEHAVIORLOG: "행동 로그",
  add_vendor: "벤더 추가",
  // rpoint_for_provider: '초기 지급 팟',
  rpoint_for_provider: "최초 에이전트 생성시 지급할 팟",
  request_pot_from_super_agent: "슈퍼 에이전트에게 팟 요청",
  cancellation: "취소",
  produce: "생성",
  add: "추가하기",
  Please_enter_numbers: "아이디는 영문+숫자+특수문자 8글자이상 여야 합니다.",
  sub_agent_rpoint_request_list: "서브  에이전트 팟 요청",
  my_requested_point: "나의 R포인트 요청",

  Consolidated_Bet_History_List: "통합 베팅 내역",
  user_name: "유저명",
  UUID: "UUID",
  vender: "벤더",
  balance: "잔액",
  paymentIn: "베팅 금액",
  paymentOut: "베팅 결과 금액",
  result: "결과",
  game_id: "게임 id",
  before: "전에",
  after: "후에",
  bet: "베팅",
  betAmount: "베팅 금액",
  resultAmount: "베팅 결과 금액",
  money: "금액",
  bet_money: "베팅 금액",
  after_money: "베팅 후 금액",
  before_money: "베팅 전 금액",
  payment_before: "이전 금액",
  payment_after: "이후 금액",
  result_money: "최종 잔액",
  amount: "금액",
  balance_before_withdraw: "이전 금액",
  balance_after_withdraw: "이후 금액",
  receiving_agent: "받은 에이전트",
  consolidated_transaction_history_list: "통합 트랜잭션 내역",
  Affiliated_Agent_ID: "제휴 에이전트 아이디",
  agent_id: "에이전트",
  Affiliated_Agent: "제휴 에이전트",
  lastStatus: "최신 상태",
  affiliated_agent: "제휴 에이전트",
  OPERATIVE_OPERATOR: "운영 에이전트",

  this_month_bet: "베팅내역",
  bet_details: "베팅내역",
  monthly_net: "손익",
  R_Points: "팟-포인트",
  API_GUIDE: "API 가이드",
  Setup_Guide: "설정 가이드",
  valid_id: "유효한 아이디를 입력해주세요",
  Invalid_nickname: "잘못된 닉네임",
  Invalid_input: "잘못된 입력",
  Invalid_Password: "잘못된 비밀번호",
  Instructions_for_use: "사용설명",
  Apply_Filter: "필터 적용",
  Unique_Request_ID: "고유 요청 아이디",
  entity: "entity",
  Description: "설명",
  Example: "예",
  Get_list_of_all_users: "모든 유저 목록을 가져옵니다.",
  Createanewuser: "새 유저를 만듭니다.",
  Game_Title: "게임 제목",
  Error_Message: "오류 메시지",
  Timestamp: "타임스탬프",
  info: "정보",
  Agent_Information_Query_API_api_users: "에이전트 정보 조회 API /API/유저",
  POST_api_users: "POST / API / 유저",
  Operator_Player_User_ID: "유저 아이디",
  Error_ID: "오류 아이디",
  Clear_Filter: "필터 지우기",
  Enter_unique_request_ID: "고유한 요청 ID를 입력하세요.",
  this_month_wing: "당첨금액",

  // profit_and_loss: "월간 손익",
  profit_and_loss: "수익",
  current_holding: "현재 잔액",
  point_currenly_held: "현재 보유 중인 포인트",
  sugn_up_date: "등록 날짜",
  balance_before_payment: "결제 전 잔액",
  user_depo_withdra_details: "유저 입출금 정보",
  period_start_date_time: "기간 시작 날짜 및 시간",
  period_end_date_time: "기간 종료 날짜 및 시간",
  AGENTNAMEID: "Agent Name(Agent Id)’s",
  Copyright: "Copyright",

  // Months Name
  January: "1월",
  February: "2월",
  March: "3월",
  April: "4월",
  May: "5월",
  June: "6월",
  July: "7월",
  August: "8월",
  September: "9월",
  October: "10월",
  November: "11월",
  December: "12월",

  setting_info: "설정 및 정보",
  name_depositor: "입금자",
  amount_request: "요청한 충전 금액",
  request_time: "요청 시간",
  today_recharge: "충전금액 (일간)",
  monthly_recharge: "충전금액 (월간)",
  consolidated_bet: "통합 베팅",
  full_history: "Full History",
  my_history: "나의 내역",
  child_history: "하위 내역",
  agent_payment_history_list: "에이전트 지급내역",

  revenue_status: "매출 현황",
  Maximumbetlimit: "최대 베팅 금액 제한",
  CallSettingManagement: "Call Setting Management",
  select_agent: "에이전트 선택",
  date: "처리일시",
  winning_amount: "당첨 금액",
  win_amount: "당첨금액",
  revenue: "수익",

  data: "날짜",
  pot_total_recived: "받은 포인트 총 금액",
  total_pot_distribution: "전송한 포인트 총 금액",
  agent_distribution_statistics_for_best: "최상의 에이전트 배포 통계",
  distribution_statistics: "배포 통계",
  casino_vendor: "카지노 밴더",
  amount_od_profit_loss: "손익금액(합계)",
  no_of_bet: "베팅 건수",
  no_of_wins: "당첨 건수",

  sub_agent_aurrent_total_holding_amount: "에이전트 총 보유금액",
  current_total_holding_of_lower_users: "하위 사용자의 현재 총 보유 금액",
  today_profit_and_loss_amount: "오늘의 손익 금액",
  number_of_bets_today: "오늘 베팅 횟수",
  number_of_users_betting_today: "오늘 배팅한 사용자 수",
  total_number_of_subAgnets: "총 하위 에이전트",
  number_of_distribution_subAgnets: "유통 서브 에이전트",
  number_of_operational_subAgents: "운영 서브 에이전트",
  number_of_concurrent_subAgents: "병행 서브 에이전트",
  number_of_allagent_subAgents: "전체 에이전트",
  number_of_transfer_subAgents: "트랜스퍼 에이전트",
  number_of_seamless_subAgents: "심리스 에이전트",
  number_of_notset_subAgents: "설정되지 않은 에이전트",
  number_of_cancelbets_subAgents: "취소 베팅 하위 에이전트 수",
  number_of_new_games: "새로운 게임 수",
  number_of_current_games: "현재 게임 수",
  number_of_deleted_games: "삭제된 게임 수",
  people: "에이전트",
  current_holding_amount: "현재 보유금액",
  current_holding_amountD: "현재 보유금액",
  sub_agent_total_holding_amount: "하위 에이전트 보유금액",
  total_holding_of_lower_users: "하위 계정 총 보유잔액",

  providers: "공급자",
  create_bet_limit: "베팅 한도 설정",
  edit_bet_limit: "베팅 한도 수정",
  category: "카테고리",
  min_bet: "최소 베팅",
  max_bet: "최대 베팅",
  enabled: "활성화",
  edit: "수정",
  action: "기능",

  select_provider_Create: "공급자 선택",
  select_game: "게임 선택",
  min_bet_limit: "최소 베팅 제한",
  max_bet_limit: "최대 베팅 제한",
  create: "생성",

  Setting_and_Information: "설정 및 정보",
  basic: "기본",
  changep_assword: "비밀번호 변경",
  basic_information: "기본 정보",
  classification: "분류",
  nickname: "닉네임",
  last_play_provider_id: "마지막 플레이 제공자 ID",
  last_play_vendor: "마지막 플레이 벤더",
  last_play_game: "마지막 플레이 게임",
  last_play_lobbie: "마지막 플레이 로비",
  invest_token: "인베스트 토큰",
  common_invest_token: "흔한 인베스트 토큰",
  api_key: "API 키",
  set_IP_allowed_for_API_calls: "API 호출에 허용된 IP 설정",
  callback_URL: "콜백 URL",
  account_number: "계좌 번호",
  sub_agent_current_total_holding_amount: "서브 에이전트 현재 총 보유 금액",
  total_amount_paid: "총 지불 금액",
  total_amount_charged: "청구된 총 금액",
  number_of_sub_agents: "총 하위 에이전트",
  number_of_lower_users: "하위 유저 수",
  all: "모두",
  sub_agent: "하위 에이전트",
  casino_users: "카지노 유저",
  set_ip: " IP 설정",
  change: "변경",
  setting_para_1:
    "API KEY는 개발에 필요한 정보입니다. 개발자에게 전달해 주세요.",
  setting_para_2:
    "API 호출 허용 IP를 등록하지 않으면 여러 API에서 호출할 수 있습니다.",
  setting_para_3:
    "일단 등록하면 수정할 수 없습니다. 변경을 원하시면 Honor Link에 변경을 요청하셔야 합니다.",
  setting_para_4: "다중, ips는 쉼표()로 구분되며 공백은 허용되지 않습니다.",
  setting_para_5: "작업 중 API 호출이 가능한 IP를 등록하는 것이 좋습니다.",
  setting_para_6:
    "CloudFlare나 Incapsula와 같은 외부 프록시 서비스를 사용하면 요구 사항이 누락됩니다.",
  setting_para_dis:
    "콜백 URL을 등록한 후, 운영자 사이트로 /changeBalance 및 /balance로 호출될 것입니다. 이것은 POST 메소드 API 여야하며, changeBalance 및 balance 엔드포인트 이름으로 끝나야합니다. 또한 HTTPS만 허용 가능합니다.",
  setting_para_7:
    "콜백 서버가 싱가포르에 위치하지 않을 경우, 빠른 회전이 불가능하고 응답 지연이 발생할 수 있습니다.",
  setting_para_8: "통합 지갑을 사용하지 않는 경우 이 값을 비워 두십시오.",

  change_password: "비밀번호 변경",
  confirm_password: "비밀번호 표시",
  current_password: "현재 비밀번호",
  new_password: "새 비밀번호",
  confirm_new_password: "새 비밀번호 확인",
  save: "저장",
  cancel: "취소",
  cancel_n: "취소",
  delete: "삭제",
  sync_admin: "동기화 관리자",
  sync_details_history: "동기화 세부사항 기록",
  sync_details_history_info: "세부사항 기록은 몇 초 후에 업데이트됩니다",
  sync_details_history_done: "세부사항 기록 동기화 완료",

  Total_api_game_history: "총 API 게임내역",
  casino_id: "유저 아이디",
  before_money: "베팅 전 잔액",
  moneySync: "머니 Sync",
  after_money: "현재 잔액",
  game_name: "게임명",
  balance_before_deposit: "신청 전 금액",
  balance_after_deposit: "신청 후 금액",
  balance_before_deposit_agent: "거래 에이전트 잔액",
  balance_after_deposit_agent: "운영 에이전트 잔액",
  win_money: "당첨금",
  // pending: '대기',

  payment: "지급",
  get_refund: "회수",
  withdraw: "출금",

  // withdraw: "출금",
  create_payment: "지급하기",
  GameType: "게임 타입",
  GAMETYPE: "게임 타입",
  TYPE: "타입",
  TUTORIAL: "사용설명",
  create_withdraw: "출금하기",
  Loading: "로드 중",
  Before_Pot: "요청전 금액",
  deposit_history: "입금 내역",
  withdraw_history: "회수 내역",
  agent_deposit_history_list: "에이전트 입금 내역",
  currency: "통화",
  userBalance: "총 사용자 잔액",
  agentBalance: "에이전트 잔액",
  KRW: "KRW",
  USD: "USD",
  JPY: "JPY",
  EUR: "EUR",
  emptyField: "입력 필드를 먼저 채워주세요",
  emptyIP: "먼저 IP를 입력하세요",
  requester: "요청자",
  round_id: "라운드 아이디",
  transaction_id: "트랜잭션 아이디",
  change_status: "상태 변경",
  status_desc:
    "기능 상태 활성화시 API 키가 활성화 될겁니다. 기능 상태 비활성화시 API 키가 비활성화 될겁니다. API 키 비활성화 기능으로 카지노 사용자의 API를 차단할 수 있습니다.",
  game_vendor: "게임 벤더",
  game_provider: "게임 공급자",
  vendor_selection: "벤더 선택",
  vendor: "벤더",
  bet_type: "베팅 유형",
  Change_agent_password: "에이전트 비밀번호 변경",
  language: "언어",
  time_zone: "시간대",
  change_time_zone: "시간대",
  statisticsByGame: "게임별 수익",
  statisticsProviderByGame: "게임별 제공자 수익",
  sub_agent_statistics: "서브 에이전트 통계",
  my_statistics: "내 통계",
  // profit_and_loss_only: "손익",
  profit_and_loss_only: "수익",
  start_date: "시작 일",
  SelectDate: "날짜 선택",
  end_date: "종료 일",
  nodata: "데이터 없음",
  total_balance: "전체 잔액",
  estimated_balance: "예상 잔액",
  Over_balance_message: "입력된 값이 현재 보유 팟을 초과합니다.",

  //  new dash board
  provider_holding: "공급자 잔액",
  KRW_Holding_Amount: " 보유금 (KRW)",
  EUR_Holding_Amount: "보유금 (EUR)",
  USD_Holding_Amount: "보유금 (USD)",
  JPY_Holding_Amount: "보유금 (JPY)",
  Amount_Can_given_for_Agent: "에이전트 에게 제공할 수 있는 금액입니다.",
  Agent_Distribution_Money: "에이전트 분배 금액",
  Cant_given_USD_for_Agent: "에이전트 에게 USD를 제공할 수 없습니다.",
  Cant_given_EUR_for_Agent: "에이전트 에게 EUR를 제공할 수 없습니다.",
  Cant_given_JPY_for_Agent: "에이전트 에게 JPY를 제공할 수 없습니다.",
  Cant_given_KRW_for_Agent: "에이전트 에게 KRW를 제공할 수 없습니다.",
  Cant_given_for_Agent: "에이전트 에게 제공할 수 없습니다.",
  title: "제목",
  sub: "분배량",
  remain: "보유량",
  distributed_by_currency: "통화별로 분배",

  //deposit History
  application_date: "신청일시",
  //nav bar
  sign_out: "로그아웃",
  //chargin history
  success: "성공",
  pending: "보류",
  //payment history
  // r_site_payment:'R 사이트 지불',
  r_site_payment: "Agent 지급",
  casino_payment: "카지노 지급",

  //deposite history
  casino_withdraw: "카지노 회수",
  // r_site_withdraw:'R 사이트 회수',
  r_site_withdraw: "Agent 회수",
  recovery: "회수",
  //user bet history
  win: "승리",
  loss: "손실",
  callbackerror: "콜백 에러",
  fail: "실패",
  inquiry: "문의하기",
  bonus: "보너스",
  view_data: "데이터 보기",
  casino_user_deposit: "카지노 사용자 입금",

  sub_agent_deposit: "서브 에이전트 입금",
  casino_user_wthdrawal: "카지노 유저 출금",
  sub_agent_wthdrawal: "서브 에이전트 출금",
  GGRUSEGELIMIT: "GGR 사용량",
  GGRusegelimitLeft: "남은 GGR 사용량 [%]",
  left: "왼쪽",
  PotStatics: "팟 통계",

  potTransactionId: "트랜잭션 ID",
  tradingAgent: "거래 에이전트",
  targetAgent: "대상(에이전트/유저)",
  Transactiontype: "타입",
  prevBalance: "이전 잔액",
  transactionAmt: "금액",
  amountAfter: "이후 금액",
  statusTransac: "상태",
  transDate: "시간",

  upperDeposit: "상부 지급",
  upperWithdraw: "상부 회수",
  paymentToSubAgent: "에이전트 머니 충전",
  fromSubAgent: "에이전트 머니 회수",
  userDeposit: "유저 출금",
  userWithdraw: "유저 지급",
  targetAgentUser: "타겟 에이전트/유저",

  //betting management
  how_to_set_maximum_bet_amount_limit: "최대 베팅 금액 한도 설정 방법",
  how_to_set_call_setting: "How to set a call setting",
  the_maximum_bet_limit_is_applied_following_order:
    "최대 베팅 금액제한은 아래와 같은 순서로 적용됩니다.",
  call_setting_is_applied_following_order:
    "The call setting is applied in the following order.",
  global_settings: "전역 설정",
  by_type: " 타입별",
  by_vendor: "벤더별",
  by_game: " 게임별",

  //my page
  copy: "복사",
  copied: "복사됨",
  inquiry_copy_popup: "CS팀이 필요한 게임 상세 정보들이 복사되었습니다.",
  TOP: "맨 위",
  lower: "lower",
  //betting management page
  classification_of_settings: "설정 분류",
  Betting_amount_management:
    "최대 배팅금액 관리 페이지 상단에 이용방법 예시를 기재해주세요.",
  Winning_amount_management:
    "최대 당첨금액 관리 페이지 상단에 이용방법 예시를 기재해주세요.",
  Game_amount_management:
    "최대 게임 금액 관리 페이지 상단에 이용 방법 예시를 기재해주세요.",
  save_settings: "설정 저장",
  excel: "엑셀",
  vendor_specific_settings: "벤더별 설정",
  settings_by_type: "유형별 설정",
  game_specific_settings: "게임별 설정",
  individual_settings_take_over_global_settings:
    "개별 설정이 전역 설정보다 우선시 됩니다.",
  for_all_games: "모든 게임 대상",
  global_settings: "글로벌 설정",
  maximum_bet_amount: "최대 베팅 금액 (단위: 베팅)",
  maximum_call_limit: "Maximum call limit (Unit:pot)",
  set_target: "대상",
  maximum_amount: "최대 금액",
  hour: "시간",

  day: "일",
  days: "일",
  week: "주",
  month: "월",
  UseR_count: "사용 팟( count )",
  Start_Pot_money: "시작 팟",
  End_pot_money: "종료 팟",
  Pot_money_Overview: "팟 개요",
  Participating_users: "참여 유저",
  Administrator_OP_Payment: "관리자->OP지급",
  Administrator_OP_withdraw: "관리자<-OP회수",
  Bet: "베팅",
  Bet_fallure_refund: "베팅실패/환불",
  Winning: "당첨",
  Sum: "합계",
  Rewards: "R환급",
  case: "건",
  number_of_people: "명",

  today: "오늘",

  day: "일",
  days: "일",
  week: "주",
  month: "월",

  Total_Deposit: "총 입금",
  Total_Deposited_Pot: "총 예치된 팟",
  Today_Deposited_Pot: "오늘 입금된 팟",
  Today_Withdraw_Pot: "오늘 인출된 금액",
  Today_Refund_Pot: "오늘 반환된 금액",
  Remain_Pot: "잔여 금액",

  today: "오늘",
  Cancel: "취소",
  Refund: "환불",
  refundMoney: "환불 돈",
  Callback_Error: "콜백 에러",
  today: "오늘",
  DAILYSUMMARY: "일일 요약",
  yesterday: "어제",
  This_Month: "이번 달",
  Past_Month: "지난 달",
  Past_3_months: "지난 3개월",
  Past_3_days: "지난 3일",
  selected_date: "선택된 날짜",
  any_date: "모든 날짜",
  error_alert: "내부 오류",
  please_change_enable_yes: "활성화 하시겠습니까?",
  bet_limit_created: "성공적으로 추가되었습니다 ",
  already_registered_on_list: "이미 등록된 내용입니다",
  // please_enter_valid_data: "올바른 정보를 입력하세요",

  please_enter_valid_data: "올바른 정보를 입력해주시기 바랍니다.",
  something_went_wrong_please_try_again:
    "오류가 발생했습니다. 다시 시도해주시길 바랍니다.",
  max_bet_deleted_successfuly: "성공적으로 삭제되었습니다",
  bet_limit_updated: "성공적으로 변경되었습니다",
  widthdraw_success: "성공적으로 출금되었습니다",
  payment_success: "성공적으로 지급되었습니다 ",
  amount_must_be_more_than: "금액을 입력하세요",
  status_has_changed: "성공적으로 수정되었습니다",
  user_details_update_successfull: "회원정보가 성공적으로 수정되었습니다 ",
  user_cration_failed: "유저 생성을 실패하였습니다",
  user_created_successfuly: "성공적으로 생성되었습니다 ",
  password_should_contain_at_least_one:
    "비밀번호 형식이 올바르지 않습니다. 8자 이상인 영문, 숫자, 특수 문자를 포함한 비밀번호만 입력 가능합니다.",
  rate_is_required: "요율을 입력하세요",
  id_should_contain_leters_and_numbers:
    "아이디에는 영문과 숫자만 입력 가능합니다",
  pot_is_required: "팟 개수를 입력하세요",
  language_is_required: "언어를 설정하세요",
  currency_is_required: "통화를 설정하세요 ",
  password_is_required: "비밀번호를 입력하세요 ",
  nick_name_is_required: "닉네임을 입력하세요 ",
  pot_request_accepted: "팟 요청이 승인되었습니다",
  id_is_required: "아이디를 입력하세요",
  pot_request_rejected: "팟 요청이 취소되었습니다 ",
  r_point_requested_successfully: "성공적으로 요청되었습니다",
  new_password_comfirm_pass_not_matched: "비밀번호가 일치하지 않습니다",
  password_changed_successfully: "성공적으로 변경되었습니다 ",
  error_occurred_please_try_again:
    "오류가 발생했습니다. 잠시 후 다시 시도해주세요",
  account_number_is_wrong: "계좌번호가 올바르지 않습니다 ",
  account_number_changed: "성공적으로 변경되었습니다 ",
  account_number_required: "계좌번호를 입력하세요 ",
  url_is_wrong: "올바르지 않는 URL입니다.",
  callback_URL_changed: "콜백 URL이 성공적으로 변경되었습니다",
  ip_changed: "성공적으로 변경되었습니다 ",
  ip_required: "아이피를 입력하세요",
  api_key_changed: "성공적으로 변경되었습니다 ",

  //notifications
  mark_all_as_read: "모두 읽음으로 표시",
  noNotification: "알림 없음",
  has_requested: "님이 요청하였습니다 ",
  r_points: "팟",
  api_coppied: "API 키가 복사 되었습니다",
  change_balance_callback_URL: "밸런스 콜백 URL 변경하기",
  balance_callback_URL: "밸런스 콜백 URL",

  //error messages
  invalid_value: "유효한 데이터를 입력하세요",
  account_no_changed_successfully: "성공적으로 변경되었습니다.",
  admin_only_can_approve: "관리자 승인만 가능합니다.",
  agent_balance_deducted: "에이전트 잔액에서 차감되었습니다.",
  agent_balance_fetched: "에이전트 잔액 결과입니다.",
  agent_holding_balance_fetched: "에이전트 보유금액 결과입니다.",
  agent_holding_summary_get: "에이전트 총 보유금 결과입니다.",
  agent_name_list_fetch_failed: "에이전트 목록 검색에 실패했습니다. ",
  agent_not_available: "에이전트 사용 불가",
  agent_not_found: "존재하지 않는 에이전트 입니다.",
  all_payment_list_fetch_failed: "지급 내역 검색에 실패했습니다.",
  allow_ips_changed_successfully: "IP가 성공적으로 변경되었습니다.",
  amount_is_required: "금액을 입력하세요.",
  api_key_invalid: "유효하지 않는 API 키입니다.",
  api_key_remove_failed: "API KEY 삭제에 실패했습니다.",
  api_key_remove_successfully: "성공적으로 삭제되었습니다.",
  api_key_required: "API 키를 입력하세요.",
  apikey_is_required: "API 키를 입력하세요",
  authentication_is_missing: "인증이 누락되었습니다.",

  balance_api_path_is_wrong: "잘못된 밸런스 API path입니다.",
  bet_creation_failed: "베팅 생성에 실패했습니다.",
  bet_data_fetch_failed: "베팅 내역 검색에 실패했습니다.",
  bet_getting_unsuccessfully: "자료가 없습니다.",
  bet_round_not_same: "베팅 회차가 일치하지 않습니다.",
  bet_transaction_id_is_required: "트랜잭션 ID를 입력하세요",
  callback_url_changed_successfully: "콜백 URL이 성공적으로 변경되었습니다.",
  cant_get_activate_provider: "프로바이더가 활성화 되지 않았습니다.",
  casino_user_balance_updated: "카지노 유저 잔액 업데이트 되었습니다.",
  casino_user_not_found: "존재하지 않는 카지노 유저입니다.",
  casino_userlist_fetched_successfully: "성공적으로 검색 되었습니다.",
  casinousername_is_required: "카지노 유저 아이디를 입력하세요.",
  changebalance_api_path_is_wrong: "잘못된 changeBalance API path입니다.",
  charge_details_fetch_failed: "충전 내역 검색에 실패했습니다.",
  charge_list_fetch_failed: "충전 목록 검색에 실패했습니다.",
  check_inputed_r_points: "팟 개수 입력을 확인해주세요.",
  currency_details: "통화 내역",
  currency_not_found: "존재하지 않는 통화입니다.",
  data_fetch_success: "성공적으로 검색 되었습니다.",
  date_filter_payment_list_fail: "선택된 날짜의 지급내역 검색에 실패했습니다.",
  date_filter_payment_list_fetched_successfully2: "성공적으로 검색 되었습니다.",
  end_date_is_required: "종료일을 입력하세요.",
  end_date_should_be_greater_than_or_equal_to_start_date:
    "올바르지 않는 종료일입니다.",
  Enter_valid_IP: "올바른 IP를 입력하세요.",
  filtered_user_details_fetch_failed: "유저내역 검색에 실패했습니다.",
  filtered_user_details_fetched_successfully: "성공적으로 검색 되었습니다.",
  game_history_data_get_failed: "게임 내역 검색에 실패했습니다 ",
  game_history_data_get_successfully: "성공적으로 검색 되었습니다.",
  get_casino_users_successfully: "성공적으로 검색 되었습니다.",
  holdings_data_fetched_successfully: "보유금 내역 성공적으로 검색 되었습니다.",
  invalid_per_page_value_it_should_be_between_1_and_1000:
    "유효하지 않는 값입니다. 1에서 1000까지만 입력 가능합니다.",
  invalid_verification_code: "올바르지 않는 인증코드입니다.",
  language_details: "언어",

  language_is_requried: "언어를 선택하세요.",
  language_not_found: "존재하지 않는 언어입니다.",
  month_agent_recharge_revenue_data_fetch_failed: "자료가 없습니다.",
  month_agent_recharge_revenue_data_fetched_successfully:
    "성공적으로 검색 되었습니다.",
  month_casino_user_data_fetched_successfully: "성공적으로 검색 되었습니다.",
  month_casino_user_data_not_found: "자료가 없습니다.",
  month_revenue_agent_data_fetch_failed: "검색에 실패했습니다.",
  month_revenue_agent_data_fetched_successfully: "성공적으로 검색 되었습니다.",
  my_point_request_data_fetched_successfully: "성공적으로 검색 되었습니다.",
  need_to_refill_rpoints: "팟 충전이 필요합니다.",
  nickname_filter_payment_list_fetch_failed: "검색에 실패했습니다.",
  no_user_found: "존재하지 않는 유저입니다.",
  only_can_accept_https_protocol: "HTTPS 프로토콜만 입력 가능합니다.",
  parent_filter_payment_list_fetch_failed: "검색에 실패했습니다.",
  parentfilter_bet_data_fetch_failed: "검색에 실패했습니다.",
  password_not_matched: "비밀번호가 일치하지 않습니다.",
  payment_list_fetch_failed: "검색에 실패했습니다.",
  payment_list_fetched_successfully: "성공적으로 검색 되었습니다.",
  payment_request_found: "지급 신청 결과입니다.",
  payment_request_not_found: "존재하지 않는 지급 신청입니다.",
  point_request_details_fetched_successfully: "성공적으로 검색 되었습니다.",
  pot_value_cant_be_less_than_0: "올바르지 않은 값입니다.",
  providers_not_activated: "프로바이더가 활성화되지 않았습니다.",
  r_points_amount_cant_be_less_than_0: "올바르지 않은 값입니다.",
  r_points_limit_exceeded: "팟 제한을 초과했습니다.",
  rpoint_balance_less_than_request: "팟이 부족합니다.",
  rpoint_request_approved: "성공적으로 승인되었습니다.",
  rpoint_request_rejected: "성공적으로 취소되었습니다.",
  rate_value_cant_be_accept: "올바르지 않는 요율입니다.",
  rate_value_cant_be_less_than_65: "6.5 이상이여야 합니다.",
  request_rpoint_failed: "팟 신청에 실패했습니다.",
  request_rpoint_successfully: "성공적으로 신청되었습니다.",
  revenue_creation_failed: "생성에 실패했습니다.",
  revenue_data_fetch_failed: "검색에 실패했습니다.",
  revenue_data_fetched_successfully: "성공적으로 검색 되었습니다.",
  search_history_details_fetched_successfully: "성공적으로 검색 되었습니다.",
  something_went_wrong: "일시적인 오류가 발생했습니다.",
  start_date_is_required: "시작일을 입력하세요.",
  sub_agent_charge_list_fetch_failed: "검색에 실패했습니다.",
  sub_agent_list_fetch_failed: "검색에 실패했습니다.",
  time_zone_is_requried: "타임존을 선택하세요.",
  type_data_fetch_failed: "검색에 실패했습니다 ",
  type_data_fetched_successfully: "성공적으로 검색 되었습니다.",
  update_faild: "업데이트 실패했습니다.",
  update_success: "성공적으로 업데이트 되었습니다.",
  new_vendor_added: "새 공급업체가 성공적으로 추가되었습니다.",
  game_added: "게임 추가됨",
  user_api_not_found: "존재하지 않는 유저 API입니다.",
  user_created_failed: "유저 생성에 실패했습니다.",
  user_details_fetched_successfully: "성공적으로 검색 되었습니다.",
  user_details_updated_successfully: "성공적으로 업데이트 되었습니다.",
  user_found: "중복된 에이전트 아이디입니다.",
  user_holding_fetching_failed: "대기 유저 검색에 실패했습니다.",
  user_holding: "사용자 보유",
  user_id_is_required: "유저 아이디를 입력하세요",
  user_is_required: "유저를 입력하세요.",
  user_list_fetch_failed: "검색에 실패했습니다.",
  user_list_fetched_successfully: "성공적으로 검색 되었습니다.",
  user_list_with_count_result_fetch_failed: "검색에 실패했습니다.",
  user_list_with_count_result_fetched_successfully:
    "성공적으로 검색 되었습니다.",
  user_not_found: "자료가 없습니다.",
  user_registration_successfull: "성공적으로 가입 되었습니다.",
  uuid_is_required: "UUID를 입력하세요.",
  vendor_is_required: "벤더를 입력하세요.",
  verification_code_confirmed: "인증코드 확인되었습니다.",
  verification_code_expired: "인증코드 유효기간이 초과되었습니다.",
  withdraw_request_found: "출금 신청 내역",
  withdraw_request_not_found: "자료가 없습니다.",
  you_cant_update_your_own_details: "정보를 수정할 수 없습니다.",
  money_less_than_request: "보유잔액이 부족합니다",
  no_data_available_in_table: "자료가 없습니다.",
  simpleTable: "간단한 테이블",
  treeTable: "트리 테이블",
  account_has_deactivated: "이 계정은 비활성화 되었습니다",
  rate_value_must_be_more_than_than_your_rate:
    "현재 에이전트 요율보다 높은 요율을 입력하세요",

  activation: "활성화",
  activated_agent: "활성화된 에이전트",
  deactivated_agent: "비활성화된 에이전트",
  live_casino: "라이브 카지노",
  slot: "슬롯",
  total: "합계",
  total_bet: "총 베팅 금액",
  total_win: "총 당첨금",

  agent_deposit: "에이전트 입금",
  agent_withdrawal: "에이전트 출금",
  deposit: "입금",

  ADMINMANAGEMENT: "관리자 관리",
  GGRLIMIT: "GGR 한도",
  OPERATORIDS: "운영자 ID",
  GENERATEPOT: "팟 가져오기",
  PREDATACONTROL: "Pre 데이터 관리",
  PREDATACTRL: "Pre 데이터 제어",

  limit: "한도",
  current_usage: "현재 사용량",
  percentage_usage: "사용량 비율",
  limit_exceeded: "한도 초과하였습니다",

  operater_id: "운영자 ID",

  deposit_to: "에 입금",
  deposit_to: "입금",
  account: "님의 계정",
  withdrawan_from: "~에서 출금",
  asynchronously: "유저 입금",
  asynchronously_withdraw: "유저 출금",

  synchronization_withdraw: "유저 출금",
  synchronization_deposit: "유저 입금",
  select_wallet: "지갑 타입 선택하기",
  wallet_type: "지갑 타입",
  select_wallet_wrong: "지갑을 선택하세요",
  GAMEMANAGEMENT: "게임 비활성화 설정",
  how_to_set_game_disable: " 게임 비활성화 하는 방법",
  the_game_enable_disable_applied_following_order:
    "게임 활성화/비활성화는 이 순서대로 적용됩니다.",
  request_data: "검색 데이터",
  user_payment_function_on_user_list: "유저 지급 기능",
  game_is_required: "게임을 선택하세요",
  game_disabled: "게임 비활성화되었습니다",
  WINMANAGEMENT: "최대 당첨 금액 설정",
  win_limit_updated: "성공적으로 업데이트되었습니다!",
  how_to_set_maximum_win_amount_limit: "당첨금 제한 설정하는 방법",
  the_maximum_win_limit_is_applied_following_order:
    "당첨금 제한은 이 순서대로 적용됩니다.",
  Maximumwinlimit: "당첨금 제한",
  maximum_win_amount: "최대 당첨 금액 (팟)",

  Ooops: "이런...",
  PageNotfound: "요청하신 페이지를 찾을 수 없습니다.",
  BackToDashboard: "대시보드로 돌아가기",
  Something_went_wrong: "문제가 발생했습니다!",
  ServerError500:
    "서버 오류 500. 당사 직원에게 알림을 보냈습니다. 이해해 주셔서 감사합니다.",
  under_maintenance: "예정된 유지 보수 중입니다.",
  Sorry_inconvenience: "불편을 끼쳐 죄송합니다. 곧 돌아오겠습니다!",
  apply_all_agents: "여기 관리자 집계기가 모든 에이전트에 추가될 것입니다",

  seamless_wallet: "심리스 지갑",
  transfer_wallet: "트랜스퍼 지갑",

  selected_date_should_not_more_one_days:
    "선택한 날짜는 현재 날짜부터 1일 이내 값이여야 합니다",

  selected_date_should_not_more_three_days:
    "선택한 날짜는 현재 날짜부터 3일 이내 값이여야 합니다",

  please_enter_valid_password: "유효한 비밀번호를 입력해주세요",
  for_game_type: "게임 유형에 대한",
  Selected_game_type: "선택한 게임 유형",
  for_vendor: "벤더를 위한",
  Selected_vendor: "선택된 벤더",
  for_game: "게임용",
  Selected_game: "선택한 게임",
  bet_limit_deleted: "베팅 제한이 삭제되었습니다.",

  Login_history: "로그인 기록",

  Login_history: "로그인 내역",
  casino_entity: "전체",
  balance_before: "이전 잔액",
  balance_after: "현재 잔액",
  agent_balance: "에이전트 잔액",
  user_balance: "사용자 잔액",
  balance_before_agent: "거래 에이전트 잔액",
  balance_after_agent: "운영 에이전트 잔액",
  available_balance: "보유 잔액",
  DisableGames: "비활성화된 게임",
  BettingLimits: "배팅 한도",
  CallLimits: "call Limit's",
  MaxWining: "최대 당첨",

  enter_code: "코드 입력",
  submit: "전송",

  addNew: "새로 추가",
  addNewGame: "신규 게임 추가",
  provideName: "제공자 이름",
  realId: "실제 ID",
  providerGameId: "제공자 게임 ID",
  realVendor: "실제 벤더",
  aggregator_provider_id: "애그리게이터 제공업체 ID",
  aggregator_provider_name: "애그리게이터 제공업체 이름",
  vendor: "벤더",
  gameType: "게임 유형",
  aggregator: "애그리게이터",
  platform: "플랫폼",
  thumbnails: "섬네일",

  login_browser: "로그인 브라우저",
  login_ip: "로그인 IP",
  login_browser_version: "로그인 브라우저 버전",
  login_system: "로그인 시스템",
  login_date: "로그인 일자",
  logout_date: "로그아웃 일자",
  login_status: "로그인 상태",
  reg_date: "등록 일자",
  enable: "활성화",
  from: "에이전트",
  to: "대상자",
  BALANCELIMIT: "잔액 한도",
  balance_limit_for_entity: "잔액 한도",
  comment: "코멘트",
  total_cancel_amount: "총 취소 금액",
  total_refund: "총 환불 금액",
  APIERRORLOG: "API 에러 로그",
  reset: "초기화",

  PROVIDER_APIERRORLOG: " 프로바이더 API 에러 로그",
  //errors
  changebalance_api_path_is_wrong: "잘못된 changeBalance API path입니다.",
  balance_api_path_is_wrong: "balance 잘못된 api 경로",
  only_can_accept_https_protocol: "Https 프로토콜만 사용하셔야 합니다",
  api_path_is_wrong: "Https 프로토콜만 사용하셔야 합니다",
  enter_valid_ip: "올바른 IP를 입력하세요.",

  username_is_required: "유저 아이디를 입력하세요",
  current_secret_key_is_required: "현재 비밀키를 입력하세요",
  new_secret_key_is_required: "새로운 비밀키를 입력하세요",
  no_record_found: "자료가 없습니다",
  secret_key_added: "비밀키가 추가되었습니다",
  secret_key_updated: "비밀키가 업데이트 되었습니다",
  please_enter_ip: "IP를 입력하세요",

  manage_login_secret: "로그인 관리",

  secret_key: "비밀키",
  new_secret_key: "새로운 비밀키",
  add_new: "추가하기",
  update_key: "업데이트_키",
  current_secret_key: "현재 비밀키",

  username: "유저 아이디",
  select_an_agent: "에이전트 선택",
  login_person: "접속중인 유저",
  error_message: "오류 메시지",
  game_title: "게임명 (벤더)",
  Operator_player_user_ID: "유저 아이디",
  round_ID: "라운드 아이디",
  timestamp: " 시간대 (아시아/서울)",

  user_payment_function_enabled: "유저 지급 기능이 활성화되었습니다",
  user_payment_function_disable: "유저 지급 기능이 비활성화되었습니다",
  check_inputed_r_points: "팟 개수 입력을 확인해주세요. ",
  enter_unique_request_Id: "유니크 아이디를 입력하세요",

  no_record_found: "기록을 찾을 수 없습니다",
  current_key_not_matched: "현재 키가 일치하지 않습니다!",
  username_already_exists: "이미 있는 사용자 이름입니다.",
  disabled: "비활성화",

  SYSTEMMANAGEMENT: "시스템 관리",
  INVESTACCOUNT: "인베스트 계정",

  investDetails: "인베스트 직접적인 세부",
  GET: "얻다",
  POST: "우편",
  PUT: "놓다",
  PATCH: "패치",
  DELETE: "삭제",
  SEND: "센드",
  Response: "리스폰스",
  response_time: "반응시간",
  Error: "오류",

  BACK_OFC: "백오피스 pw",
  BACK_OFC_USER: "백오피스 사용자",
  Opcode: "Op 코드",
  callback_url_customization: "콜백 URL 커스터마이징",
  Add_Edit_Account: "계정 추가/편집",
  update: "업데이트",
  balance_limit_transactions: "밸런스 제한 내역",

  target: "대상 에이전트",
  Solution_Management: "솔루션",
  calander: { ...Korean },
  no_option: "옵션 없음",

  limit_should_be_more_than_0: "한도는 0보다 커야 합니다.",

  GAMELISTMANAGEMENT: "게임 리스트",
  PLATFORMLOGS: "플랫폼 로그",
  ENVCONTROLLER: "환경 제어 관리",
  AGGREGATORMANAGEMENT: "게임사 벤더",
  new: "신규 게임",
  current: "현재 게임",
  deleted: "삭제된 게임",
  game_code: "게임 코드",
  game_enabled: "활성화",
  platform: "플랫폼",
  date_registerd: "등록일시",
  win_limit_deleted: "당첨금 제한이 삭제되었습니다.",

  MAX: "최대",

  current_games: " 현재 게임",
  deleted_games: "삭제된 게임",
  new_games: "새로운 게임",

  //bet history
  warning_massage_1:
    "동시에 여러 게임을 진행하거나, 포커(1회 두번이상의 베팅)와 같이 진행되는 게임의 경우 이후 잔액의 표기의 순서 및 결과가 다르게 나타날 수 있습니다.",
  warning_massage_2: "실제 유저 잔액은 정상이므로 운영에 참고 부탁드립니다.",
  number_of_cancelbets_today: "오늘의 취소 베팅",

  USERBETMANAGEMENT: "사용자별 베팅 제한 설정",

  Round: "라운드",
  User: "유저",
  Agent: "에이전트",
  Agennameisrequired: "Agent name is required",
  FruitKing: "과일왕",

  Game_image_url: "게임 이미지 URL",
  date_should_not_more_than_one_day: "1일 이상 검색하실 수 없습니다!",
  total_amout_recevied: "총 지급받은 금액",
  please_enter_valid_vendor: "올바른 정보를 입력해주시기 바랍니다!",
  please_enter_valid_data_for_game_type:
    "게임 유형에 대한 올바른 정보를 입력해 주시기 바랍니다!",
  statics: "Statics",
  casino_user_name_is_required: "카지노 사용자 이름이 필요합니다",
  bottom: "하부",
  total_users: "전체 유저 수",
  number_of_new_agents: "금일 신규 에이전트",
  sum: "총 보유금액",
  this_month_win_amount: "이번 달 총 당첨 금액",
  this_month_bet_count: "이번 달 베팅 횟수",
  this_month_profit: "이번 달 수익",
  this_month_bet_amount: "이번 달 총 베팅 금액",
  today_win_amount: "오늘의 당첨 금액",
  today_bet_count: "오늘의 베팅 횟수",
  today_profit: "오늘의 수익",
  profit: "이익",
  no_user: "현재 사용자가 없습니다",
  today_bet_amount: "오늘의 총 베팅 금액",
  chart: "차트",
  Edit_Game: "게임 편집",
  Gates_of_Olympus: "Gates of Olympus",
  losing: "잃고 있는",
  winning: "이기는",
  five_million: "5천만",
  hundred_million: "1억",
  three_hundred_million: "3억",
  five_hundred_million: "5억",
  one_billion: "10억",

  agent_name_is_required: "에이전트 명은 필수값입니다.",

  ALLAGENTREVENUESTAT: "전체 에이전트 매출",
  casino_user_count: "유저수",

  last_login_date: "최종 로그인 날짜",
  disable_time: "비활성화 시간",
  deposit_money: "입금 금액",
  widthraw_money: "출금 금액",
  refund: "회수 금액",
  admin_casino_users: "카지노 관리자 사용자",
  sub_agent_pot_history: "하위 에이전트 팟 히스토리",
  agent_refund: "에이전트 환불",
  my: "내 정보",

  Pleased_enter_agent_uid_and_casino_username:
    "에이전트 UID와 카지노 사용자 이름을 입력하십시오",
  please_contact_admin_make_sure_invest_account_metadata_is_available:
    "관리자에게 문의하십시오. 투자 계좌 메타데이터가 준비되어 있는지 확인해 주십시오.",
  gameStatusManagement: "게임 상태 관리",
  isplayable: "플레이 가능 여부",
  disable_play: "플레이 비활성화",
  enable_play: "플레이 활성화",
};
export default locale;
